import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { RouteProps } from 'react-router-dom'
import { media } from 'src/media'

const Root = styled.div`
  background: #eff5fe;
  min-height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 130px 48px 0;

  ${media.tablet`
    padding: 130px 30px 0;
    min-height: 100vh;
  `}
`

const Twologoslink = styled.a`
  display: block;

  :hover {
    cursor: pointer;
  }
`

const IconWrap = styled.img`
  width: 119px;
  height: 95px;
  margin-bottom: 65px;

  ${media.mobile`
    width: 80px;
    height: 64px;
    margin-bottom: 45px;
  `}
`

const MainWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 78px;

  ${media.laptop`
    margin: 0 30px;
  `}

  ${media.tablet`
    margin: 0 10px;
  `}
`

const IconLaurel = styled.img`
  width: 112px;
  height: 430px;

  ${media.tablet`
    display: none;
  `}
`

const Title = styled.h1`
  font-family: var(--fonts-second);
  font-size: 52px;
  line-height: 55px;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-weight: 400;
  text-align: center;

  ${media.tablet`
    font-size: 40px;
    line-height: 45px;
  `}

  ${media.mobile`
    font-size: 32px;
    line-height: 38px;
  `}

  ${media.premobile`
    font-size: 30px;
    line-height: 35px;
  `}
`

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 25px;
  color: var(--color-ground-1000);
  margin-bottom: 40px;
  text-align: center;

  ${media.tablet`
    margin-bottom: 40px;
    max-width: 300px;
  `}

  ${media.mobile`
    display: none;
  `}
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 10px;

  ${media.mobile`
    margin: 0;
  `}
`

const Button = styled.a`
  padding: 17.5px 71px;
  display: flex;
  align-items: center;
  background-color: #326fe1;
  border-radius: 50px;
  color: var(--color-white);
  transition: 0.3s;

  :first-child {
    ${media.tabletX`
      margin-right: 0;
    `}
  }

  ${media.tablet`
    width: 100%;
    justify-content: center;
  `}

  :hover {
    cursor: pointer;
    box-shadow: 0px 2px 10px 0px #201f6412;
    box-shadow: 0px 12px 20px 0px #201f640a;
    background-color: #558aed;
  }
`

const Footer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  padding: 22px 160px 30px;
  background: #eff5fe;

  ${media.laptop`
    padding: 22px 48px 30px;
  `}
`

const Footerlink = styled.a`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  opacity: 50%;
  color: var(--color-ground-1000);
  min-width: 50px;
  text-align: end;

  :hover {
    cursor: pointer;
  }
`

const FooterText = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  opacity: 50%;
  color: var(--color-ground-1000);

  ${media.tablet`
    display: none;
  `}
`

export const PageUninstall: React.FC<RouteProps> = observer(() => {
  return (
    <Root>
      <MainWrapper>
        <IconLaurel width="112" height="306" src="/assets/laurel-left.svg" alt="Laurel Logo" />
        <ContentWrapper>
          <Twologoslink
            href="https://pallie.ai/?utm_source=breathhh&utm_medium=uninstall_page&utm_campaign=partnership&utm_content=general"
            target="_blank"
          >
            <IconWrap width="119" height="95" src="/assets/pallie-emblem.svg" alt="Pallie Logo" />
          </Twologoslink>
          <Title>
            Meet the Top&#8209;Secret
            <br /> Wellbeing AI Buddy 🤫
          </Title>
          <Subtitle>
            Hyper-Personal, Empathetic, Proactive. Right in you favorite messenger!
          </Subtitle>
          <ButtonWrapper>
            <Button
              href="https://pallie.ai/steps?utm_source=breathhh&utm_medium=uninstall_page&utm_campaign=partnership&utm_content=ai_buddy"
              target="_blank"
            >
              Start Chatting
            </Button>
          </ButtonWrapper>
        </ContentWrapper>
        <IconLaurel width="112" height="306" src="/assets/laurel-right.svg" alt="Laurel Logo" />
      </MainWrapper>
      <Footer>
        <Footerlink href="https://breathhh.app" target="_blank">
          Breathhh
        </Footerlink>
        <FooterText>Two Products, One Purpose</FooterText>
        <Footerlink
          href="https://pallie.ai/?utm_source=breathhh&utm_medium=uninstall_page&utm_campaign=partnership&utm_content=general"
          target="_blank"
        >
          Pallie
        </Footerlink>
      </Footer>
    </Root>
  )
})
